// import PropTypes from 'prop-types';
// material-ui
import {
  Button,
  ButtonGroup,
  Dialog,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import {
  setFavoriteOpen,
  setMyQuizesOpen,
  setQuizOpen,
  setSelectedCareer,
  setSelectedQuiz,
  urlChangedAction,
} from "actions";
import { getJsonFromUrl } from "helpers";
import CareerView from "hooks/CareerView";
import CategoriesTree from "hooks/CategoriesTree";
import CPFMFavorite from "hooks/CPFMFavorite";
import CPFMLogo from "hooks/CPFMLogo";
import CPFMSearch from "hooks/CPFMSearch";
import FavoriteCareers from "hooks/FavoriteCareers";
import FeaturedCareerGallery from "hooks/FeaturedCareerGallery";
import Graph from "hooks/Graph";
import MyQuizes from "hooks/MyQuizes";
import NewsItem from "hooks/NewsItem";
import QuickSearch from "hooks/QuickSearch";
import Quiz from "hooks/Quiz";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { store } from "store";
import quizBackground from "./quiz-background.jpg";
// styles
import styles from "./styles";

const maxWidth = 1400;

const useStyles = makeStyles(styles);
export default function App() {
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState();
  const classes = useStyles(styles);
  const quizOpen = useSelector((state) => state.studentQuizzes.quizOpen);
  const fullApp = useSelector((state) => state.fullApp);
  const studentQuizzes = useSelector((state) => state.studentQuizzes.all);
  const dispatch = useDispatch();
  const graphOpen = useSelector((state) => state.graph.graphOpen);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      dispatch(urlChangedAction(location));
    });
  }, []);

  useEffect(() => {
    const urlParams = getJsonFromUrl(window.location);
    if (urlParams.i) {
      setValue(Number(urlParams.i));
    }
  }, []);

  useEffect(() => {
    const urlParams = getJsonFromUrl(window.location);
    if (urlParams.careerID && fullApp) {
      dispatch(
        setSelectedCareer(
          fullApp.find((c) => c.id === Number(urlParams.careerID))
        )
      );
    }
    if (urlParams.quiz) {
      dispatch(setQuizOpen(true));
    }
  }, [fullApp]);

  function getStepContent() {
    switch (value) {
      case 0:
        return (
          <div style={{ flex: 1 }}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              style={{
                maxWidth: maxWidth,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <CPFMLogo />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingRight: 16,
                }}
              >
                <Typography
                  href="https://student.collegeprepformusicians.com/"
                  onClick={() => {
                    dispatch(setQuizOpen(true));
                  }}
                  style={{
                    paddingRight: 16,
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                    fontWeight: 800,
                  }}
                  variant="link"
                >
                  Quiz
                </Typography>
                {studentQuizzes.length ? (
                  <>
                    <Typography
                      href="https://student.collegeprepformusicians.com/"
                      onClick={(e) => {
                        store.dispatch(setMyQuizesOpen(true));
                      }}
                      style={{
                        paddingRight: 16,
                        color: "black",
                        textDecoration: "none",
                        cursor: "pointer",
                        fontWeight: 800,
                      }}
                      variant="link"
                    >
                      Profile{studentQuizzes.length > 1 ? "s" : []}
                    </Typography>
                    {/* <Menu
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl()}
                    >
                      {studentQuizzes.map((s) => (
                        <MenuItem
                          onClick={() => {
                            dispatch(Actions.setSelectedQuiz(s.date));
                            setAnchorEl();
                            dispatch(setQuizOpen(true));
                          }}
                        >
                          <ListItemText
                            primary={`${moment(s.date).format("lll")}`}
                            secondary={`${s.careers.length} career${
                              s.careers.length > 1 ? "s" : ""
                            } found`}
                          />
                        </MenuItem>
                      ))}
                    </Menu> */}
                  </>
                ) : (
                  []
                )}
                <Typography
                  href="https://student.collegeprepformusicians.com/"
                  onClick={() => dispatch(setFavoriteOpen(true))}
                  style={{
                    paddingRight: 16,
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                    fontWeight: 800,
                  }}
                  variant="link"
                >
                  Favorites
                </Typography>
                <Hidden xsDown>
                  <QuickSearch />
                </Hidden>
              </Grid>
              <Hidden smUp>
                <Grid item xs={12} style={{ padding: 8 }}>
                  <QuickSearch />
                </Grid>
              </Hidden>
            </Grid>
            <Grid
              container
              justify="center"
              style={{
                padding: 16,
                maxWidth: maxWidth,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  padding: 20,
                  marginTop: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: 400,
                  textAlign: "center",
                  borderRadius: 4,
                  backgroundImage: `url(${quizBackground})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <Typography
                  variant="h2"
                  style={{
                    color: "black",
                    fontWeight: 700,
                    fontFamily: "cooper-black-std, serif",
                  }}
                >
                  Start here:
                </Typography>
                <Button
                  onClick={() => dispatch(setQuizOpen(true))}
                  style={{
                    marginTop: 20,
                    color: "black",
                    fontWeight: 600,
                    fontSize: 18,
                    borderColor: "white",
                    background: "#A5DAFF",
                  }}
                >
                  Take the Careers in Music Skills Quiz!
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                maxWidth: maxWidth,
                marginLeft: "auto",
                marginRight: "auto",
                padding: 16,
                marginTop: 20,
              }}
            >
              <Grid item xs={12} md={4} style={{ padding: 16 }}>
                <NewsItem
                  title={"Step 1: Find Your Match. 👨🏽‍💻"}
                  body={
                    <span>
                      Take the Careers in{" "}
                      <u
                        style={{ cursor: "pointer" }}
                        onClick={() => dispatch(setQuizOpen(true))}
                      >
                        Music Skills Quiz
                      </u>{" "}
                      to find a starting point for your journey.
                    </span>
                  }
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ padding: 16 }}>
                <NewsItem
                  title={"Step 2: Discover. 🕵️‍♂️"}
                  body={
                    <span>
                      There are hundreds of Careers in Music! Check out your{" "}
                      <u
                        style={{ cursor: "pointer" }}
                        onClick={() => dispatch(setQuizOpen(true))}
                      >
                        quiz results
                      </u>{" "}
                      and then explore more careers on your own.
                    </span>
                  }
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ padding: 16 }}>
                <NewsItem
                  title={"Step 3: Dive Deep. 🎥"}
                  comingSoon
                  body={
                    <span>
                      Hear from successful musicians about their careers and
                      check our all of our{" "}
                      <a
                        target="_blank"
                        style={{ color: "inherit" }}
                        href="https://map.collegeprepformusicians.com"
                      >
                        additional resources.
                      </a>
                    </span>
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 20,
              }}
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: "cooper-black-std, serif",
                    flex: 1,
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  Featured Careers
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                <FeaturedCareerGallery random />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 20,
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: "cooper-black-std, serif",
                    flex: 1,
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  Discover
                </Typography>
              </Grid>
              {/* <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ImageExporter variant="bubble" />
              </Grid> */}
              <Grid
                item
                xs={12}
                style={{ position: "sticky", top: 0, zIndex: 999 }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1 }} />
                  {/* <Hidden smDown> */}
                  <div
                    style={{
                      flex: 1,
                      textAlign: "right",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      padding: 8,
                    }}
                  >
                    <ButtonGroup
                      color="primary"
                      size="small"
                      aria-label="primary button group"
                    >
                      <Button
                        style={{
                          background: "#F6C47D",
                          border: "none",
                          color: "black",
                        }}
                      >
                        List View
                      </Button>
                      <Button
                        style={{
                          border: "none",
                          color: "black",
                          background: "#D1D1D1",
                        }}
                        onClick={() => dispatch(Actions.setGraphOpen(true))}
                      >
                        Graph View
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} style={{ minHeight: "100vh" }}>
                <Dialog
                  open={graphOpen}
                  onClose={() => dispatch(Actions.setGraphOpen(false))}
                  fullScreen
                >
                  <Graph />
                </Dialog>
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: 10,
                    paddingLeft: 30,
                    paddingBottom: 60,
                  }}
                >
                  <CategoriesTree />
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div></div>
          </div>
        );
      case 2:
        return (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              paddingTop: 20,
              maxWidth: maxWidth,
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              position: "relative",
            }}
          >
            <div
              style={{
                textAlign: "center",
                width: "100%",
                padding: 20,
                paddingBottom: 100,
              }}
            >
              <Typography variant="h4" className={classes.kimi}>
                All Careers You Loved
              </Typography>
              <CPFMFavorite />
            </div>
          </div>
        );
      case 3:
        return (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              paddingTop: 20,
              maxWidth: maxWidth,
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              position: "relative",
            }}
          >
            <div
              style={{
                padding: 20,
                paddingBottom: 100,
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Typography variant="h4">Search</Typography>
              </div>
              <Typography>Search Careers:</Typography>

              <CPFMSearch />
            </div>
          </div>
        );
      default:
        return <div />;
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>{getStepContent()}</div>
      <CareerView />
      <Quiz
        quizOpen={quizOpen}
        close={() => {
          dispatch(setQuizOpen(false));
          dispatch(setSelectedQuiz());
        }}
      />
      <MyQuizes />
      <FavoriteCareers />
    </div>
  );
}
