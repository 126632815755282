// import PropTypes from 'prop-types';
// material-ui
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import * as Actions from "actions";
import { setQuizOpen } from "actions";
import CIMFooterGradientOnly from "assets/image/CIMFooterGradientOnly.jpg";
import FeatureCareerCard from "hooks/FeatureCareerCard";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);
export default function MyQuizes() {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);
  const myQuizesOpen = useSelector((state) => state.app.myQuizesOpen);
  const mobile = !useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const fullApp = useSelector((state) => state.fullApp);
  const studentQuizzes = useSelector((state) => state.studentQuizzes.all);
  const dispatch = useDispatch();

  function close() {
    dispatch(Actions.setMyQuizesOpen(false));
  }

  console.log(studentQuizzes);

  const handleCareerClick = (c) => () => {
    dispatch(Actions.setSelectedCareer(c));
  };

  return (
    <Dialog
      open={myQuizesOpen}
      onClose={close}
      fullScreen
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: mobile ? classes.paperSm : classes.paper,
      }}
    >
      <div
        onClick={close}
        style={{ position: "absolute", top: 10, right: 10, zIndex: 999 }}
      >
        <IconButton>
          <Close />
        </IconButton>
      </div>
      <DialogContent>
        <div
          style={{
            backgroundImage: `url(${CIMFooterGradientOnly})`,
            backgroundSize: "cover",
            padding: 68,
            borderRadius: 24,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 16,
            marginRight: 32,
          }}
        >
          <Typography
            variant="h4"
            style={{
              fontFamily: "cooper-black-std, serif",
              textAlign: "center",
            }}
          >
            See your past Careers in Music Skills Quiz results <br />
            or take the quiz again!
          </Typography>
        </div>
        <Grid container alignItems="stretch" justify="space-between">
          {studentQuizzes.map((c) => (
            <>
              <Grid item>
                <Typography>{`${moment(c.date).format("lll")}`}</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    dispatch(Actions.setSelectedQuiz(c.date));
                    dispatch(setQuizOpen(true));
                  }}
                >
                  See full result <i class="fa-solid fa-angle-right p-left"></i>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="stretch">
                  {c.careers.slice(0, 4).map((f) => (
                    <Grid item xs={mobile ? 12 : 3}>
                      <FeatureCareerCard
                        career={{ ...fullApp.find((a) => a.id === f.id), ...f }}
                        onClick={handleCareerClick}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
