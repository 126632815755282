// import PropTypes from 'prop-types';
// material-ui
import {
  AppBar,
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Hidden,
  Icon,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Snackbar,
  SnackbarContent,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  ArrowBackIos,
  ArrowForwardIos,
  CenterFocusStrong,
  Close,
  ExitToApp,
  FavoriteBorder,
  HelpOutline,
  OpenInBrowser,
  Pause,
  PlayArrow,
  Refresh,
  ThreeSixty,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import * as Actions from "actions";
import {
  setCategoriesEnabled,
  setDagMode,
  setFavoriteOnly,
  setGraphOpen,
  setPlayCareerIndex,
  setQuizOpen,
} from "actions";
import hotkeys from "hotkeys-js";
import moment from "moment";
import React, { useEffect } from "react";
import ForceGraph3D from "react-force-graph-3d";
import { useDispatch, useSelector } from "react-redux";
import cim from "./cim.png";

const SHOW_CAREER_DURATION = 5000;

export default function Graph3D() {
  const [data, setData] = React.useState({ nodes: [], links: [] });
  const [orbitInterval, setOrbitInterval] = React.useState(false);
  const [playInterval, setPlayInterval] = React.useState(null);
  const [helpOpen, setHelpOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState();
  const [displayModeAnchorEl, setDisplayModeAnchorEl] = React.useState();
  const {
    selectedCareerOnGraph,
    orbiting,
    showUnsuggestedCareers,
    selectedQuizOnGraph,
    playCareerIndex,
    guidedTourPlaying,
    favoriteOnly,
    categoriesEnabled,
    dagMode,
  } = useSelector((state) => state.graph);
  const categories = useSelector((state) => state.cpfmCategories);
  const fullApp = useSelector((state) => state.fullApp);
  const careerPreferences = useSelector((state) => state.careerPreferences);
  const fgRef = React.useRef();
  const dispatch = useDispatch();
  const studentQuizzes = useSelector((state) => state.studentQuizzes.all);
  const careersGraphMap = {};

  const mobile = !useMediaQuery((theme) => theme.breakpoints.up("sm"));

  // gen Graph
  React.useEffect(() => {
    if (fullApp && categories) {
      populateGraph();
    }
  }, [
    categories,
    fullApp,
    selectedQuizOnGraph,
    favoriteOnly,
    showUnsuggestedCareers,
    categoriesEnabled,
  ]);

  // keyoard shortcuts
  React.useEffect(() => {
    hotkeys("*", function (e) {
      if (e.code === "Space") {
        dispatch(Actions.toggleGuidedTourPlaying());
      }
      if (e.key === "ArrowUp") {
        dispatch(Actions.openSelectedCareerOnGraph());
      }
      if (e.key === "ArrowDown") {
        dispatch(Actions.setSelectedCareer());
      }
      if (e.key === "ArrowRight") {
        dispatch(Actions.setGuidedTourPlaying(false));
        dispatch(Actions.incrementPlayCareerIndex());
      }
      if (e.key === "ArrowLeft") {
        dispatch(Actions.setGuidedTourPlaying(false));
        dispatch(Actions.decrementPlayCareerIndex());
      }
    });

    return () => {
      hotkeys.deleteScope("*");
      hotkeys.unbind("*");
    };
  }, []);

  // Camera orbiting
  useEffect(() => {
    if (orbitInterval) clearInterval(orbitInterval);
    if (orbiting) {
      fgRef.current.cameraPosition({ z: 900 });

      // camera orbit
      let angle = 0;
      setOrbitInterval(
        setInterval(() => {
          if (fgRef?.current?.cameraPosition)
            fgRef.current.cameraPosition({
              x: 900 * Math.sin(angle),
              z: 900 * Math.cos(angle),
            });
          angle += Math.PI / 3000;
        }, 10)
      );
    }

    return () => clearInterval(orbitInterval);
  }, [orbiting]);

  // Guided tour playing/stopping
  useEffect(() => {
    if (guidedTourPlaying && selectedQuizOnGraph) {
      setPlayInterval(
        setInterval(() => {
          if (playCareerIndex === selectedQuizOnGraph.careers.length - 1) {
            clearInterval(playInterval);
            setPlayInterval();
            return;
          }
          dispatch(Actions.incrementPlayCareerIndex());
        }, SHOW_CAREER_DURATION)
      );
    } else {
      clearInterval(playInterval);
      setPlayInterval();
    }
  }, [guidedTourPlaying]);

  React.useEffect(() => {
    if (selectedQuizOnGraph) {
      dispatch(setPlayCareerIndex());
    }
  }, [selectedQuizOnGraph]);

  // Next/Previous Career when quiz is selected
  React.useEffect(() => {
    if (
      playCareerIndex !== undefined &&
      selectedQuizOnGraph &&
      selectedQuizOnGraph?.careers
    ) {
      try {
        const nodeID = `career_${selectedQuizOnGraph.careers[playCareerIndex].id}`;
        const node = data.nodes.find((n) => n.id === nodeID);
        if (node !== undefined) {
          setTimeout(
            () => dispatch(Actions.setSelectedCareerOnGraph(node.career)),
            100
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [playCareerIndex]);

  // // Career has been selected on graph
  React.useEffect(() => {
    if (selectedCareerOnGraph !== undefined) {
      try {
        const nodeID = `career_${selectedCareerOnGraph.id}`;
        const node = data.nodes.find((n) => n.id === nodeID);
        if (node) moveCameraTo(node);
      } catch (error) {}
    }
  }, [selectedCareerOnGraph]);

  function runReport(d) {
    const n = [];
    const l = [];
    var issues = [];

    for (const key in d.nodes) {
      if (Object.hasOwnProperty.call(d.nodes, key)) {
        const node = d.nodes[key];
        if (n[node.id]) {
          issues.push(`found duplicate for node: ${node.id}`);
        }
        n[node.id] = true;
      }
    }

    for (const key in d.links) {
      if (Object.hasOwnProperty.call(d.links, key)) {
        const link = d.links[key];
        const id = `${link.source.id}_${link.target.id}`;
        if (l[id]) {
          issues.push(`found duplicate for link: ${id}`);
        }
        l[id] = true;
        if (n[link.source] === undefined) {
          issues.push(`source not found for link ${id}`);
        }
        if (n[link.target] === undefined) {
          issues.push(`target not found for link ${id}`);
        }
      }
    }

    if (issues.length) {
      alert(`issues found on Graph`);
      console.log(issues);
      console.log(d);
    }
  }

  function populateGraph() {
    const _data = setCategoriesData();
    setData(_data);
  }

  function setCategoriesData() {
    var _data = {
      nodes: [
        {
          id: `cpfm`,
          type: "cpfm",
          color: "#607d8b",
          name: "Careers in Music",
        },
      ],
      links: [],
    };
    for (const key in categories) {
      if (Object.hasOwnProperty.call(categories, key)) {
        const category = categories[key];
        if (categoriesEnabled[category.id] === false) continue;
        var categoryAdded = false;

        if (category.subCategories) {
          const res = setSubCategoryCareers(_data, category);
          if (res.added) {
            _data = res._data;
            categoryAdded = true;
          }
        } else if (category.careerIDs?.length) {
          const res = setCategoryCareers(_data, category);
          if (res.added) {
            _data = res._data;
            categoryAdded = true;
          }
        }
        if (categoryAdded) {
          const node = getNodeForType(category, category.color, "category");
          _data.nodes.push(node);
          _data.links.push({
            target: `category_${category.id}`,
            source: `cpfm`,
            color: category.color,
          });
        }
      }
    }

    return _data;
  }

  function setCategoryCareers(_data, category) {
    var added = false;
    for (const key in category.careerIDs) {
      if (Object.hasOwnProperty.call(category.careerIDs, key)) {
        const res = addCareerToData(
          _data,
          category.careerIDs[key],
          category,
          "category",
          category.color
        );

        if (res.added) {
          _data = res._data;
          added = true;
        }
      }
    }

    return { _data, added };
  }

  function setSubCategoryCareers(_data, category) {
    var added = false;

    for (const key in category?.subCategories) {
      if (Object.hasOwnProperty.call(category?.subCategories, key)) {
        const subCategory = category?.subCategories[key];
        var subCategoryAdded = false;

        // Careers
        for (const key in subCategory.careerIDs) {
          if (Object.hasOwnProperty.call(subCategory.careerIDs, key)) {
            const res = addCareerToData(
              _data,
              subCategory.careerIDs[key],
              subCategory,
              "subCategory",
              category.color
            );
            if (res.added) {
              _data = res._data;
              added = true;
              subCategoryAdded = true;
            }
          }
        }

        if (subCategoryAdded) {
          const node = getNodeForType(
            subCategory,
            category.color,
            "subCategory"
          );
          _data.nodes.push(node);
          _data.links.push({
            source: `category_${category.id}`,
            target: `subCategory_${subCategory.id}`,
            color: category.color,
          });
        }
      }
    }

    return { _data, added };
  }

  function addCareerToData(_data, careerID, object, type, color) {
    let career = fullApp.find((f) => f.id === careerID);

    if (!career || !shouldDisplay(careerID)) {
      return { _data, added: false };
    }

    var careerExists = false;
    if (careersGraphMap[careerID]) {
      careerExists = true;
    } else {
      careersGraphMap[careerID] = true;
    }

    if (!careerExists) {
      const node = getNodeForType(career, "", "career");
      _data.nodes.push(node);
    }

    _data.links.push({
      target: `career_${career.id}`,
      source: `${type}_${object.id}`,
      color: color,
    });

    return { _data, added: true };
  }

  function getNodeForType(object, color, type) {
    var res = {};

    res.id = `${type}_${object.id}`;
    res.name = object?.title;
    res.type = type;

    if (type === "career") {
      res[type] = object;
    } else {
      res.color = color;
    }
    return res;
  }

  function shouldDisplay(careerID) {
    var display = false;

    if (favoriteOnly && !selectedQuizOnGraph) {
      display = careerPreferences[careerID]?.love ?? false;
    } else if (
      !selectedQuizOnGraph ||
      (showUnsuggestedCareers && !favoriteOnly)
    ) {
      display = true;
    } else {
      var res = selectedQuizOnGraph.careers.find(
        (c) => `${c.id}` === `${careerID}`
      );

      if (res !== undefined && favoriteOnly) {
        display = careerPreferences[careerID]?.love ?? false;
      } else {
        display = res !== undefined;
      }
    }

    return display;
  }

  const handleRightClick = (node, e) => {
    if (node?.type === "CAREER") {
      dispatch(Actions.setSelectedCareer(node.career));
    }
  };

  const moveCameraTo = React.useCallback(
    (node) => {
      // Aim at node from outside it
      const distance = 200;
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

      fgRef.current.cameraPosition(
        {
          x: node.x * distRatio,
          y: node.y * distRatio,
          z: node.z * distRatio,
        }, // new position
        node, // lookAt ({ x, y, z })
        2000 // ms transition duration
      );
    },
    [fgRef]
  );

  function renderCPFMNode(node) {
    const imgTexture = new window.THREE.TextureLoader().load(`${cim}`);
    const imgMaterial = new window.THREE.SpriteMaterial({
      map: imgTexture,
      transparent: true,
      sizeAttenuation: true,
    });
    const imageSprite = new window.THREE.Sprite(imgMaterial);
    imageSprite.scale.set(70, 56, 4);

    return imageSprite;
  }

  function renderCareerNode(node) {
    const careerID = node.career.id;
    const love = careerPreferences[careerID]?.love ?? false;
    const url = love
      ? `https://storage.googleapis.com/wos-data/love/love_career_${careerID}.png`
      : `https://storage.googleapis.com/wos-data/bubble/bubble_career_${careerID}.png`;
    const imgTexture = new window.THREE.TextureLoader().load(url);

    var opacity = 1;
    if (
      showUnsuggestedCareers &&
      selectedQuizOnGraph &&
      selectedQuizOnGraph?.careers?.find(
        (c) => `career_${c.id}` === node.id
      ) === undefined
    ) {
      opacity = 0.2;
    }
    const imgMaterial = new window.THREE.SpriteMaterial({
      map: imgTexture,
      transparent: true,
      sizeAttenuation: true,
      opacity,
    });

    const imageSprite = new window.THREE.Sprite(imgMaterial);
    imageSprite.scale.set(20, 20, 4);

    return imageSprite;
  }

  function renderSubCategoryNode(node) {
    const mesh = new window.THREE.Mesh(
      new window.THREE.DodecahedronGeometry(10),
      new window.THREE.MeshLambertMaterial({
        color: node.color,
        transparent: false,
        opacity: 0.75,
      })
    );

    return mesh;
  }

  function renderCategoryNode(node) {
    const mesh = new window.THREE.Mesh(
      new window.THREE.BoxGeometry(20, 20, 20),
      new window.THREE.MeshLambertMaterial({
        color: node.color,
        transparent: true,
        opacity: 0.75,
      })
    );

    return mesh;
  }

  const memoizedGraph = React.useMemo(() => {
    const extraRenderers = [new window.THREE.CSS2DRenderer()];
    return (
      <ForceGraph3D
        ref={fgRef}
        graphData={data}
        nodeRelSize={0}
        nodeLabel="name"
        dagMode={dagMode}
        showNavInfo={false}
        onBackgroundClick={() => dispatch(Actions.setSelectedCareerOnGraph())}
        onNodeClick={(n) =>
          dispatch(Actions.setSelectedCareerOnGraph(n.career))
        }
        onNodeRightClick={handleRightClick}
        backgroundColor={"white"}
        onNodeDragEnd={(node) => {
          node.fx = node.x;
          node.fy = node.y;
          node.fz = node.z;
        }}
        extraRenderers={extraRenderers}
        linkWidth={0.5}
        linkOpacity={0.7}
        id="3d-graph"
        nodePointerAreaPaint={() => console.log("nodePointerAreaPaint")}
        nodeThreeObject={(node) => {
          switch (node.type) {
            case "career":
              return renderCareerNode(node);
            case "category":
              return renderCategoryNode(node);
            case "subCategory":
              return renderSubCategoryNode(node);
            default:
              return renderCPFMNode(node);
          }
        }}
        nodeThreeObjectExtend={true}
      />
    );
  }, [data]);

  function handleCategoriesClicked(e) {
    setAnchorEl(e.currentTarget);
  }

  if (!fullApp || !categories) return <div />;

  const scoreForSelectedCareer =
    selectedCareerOnGraph &&
    selectedQuizOnGraph?.careers?.find((c) => c.id === selectedCareerOnGraph.id)
      ?.quizWeight;

  return (
    <>
      <AppBar
        position="absolute"
        style={{
          top: 0,
          zIndex: 999,
          background: "rgba(0,0,0,0.7)",
          backdropFilter: "blur(20px)",
          WebkitBackdropFilter: "blur(20px)",
          color: "white",
        }}
      >
        <Toolbar>
          <Hidden smDown>
            <div>
              <Tooltip title="Help">
                <IconButton color="inherit" onClick={() => setHelpOpen(true)}>
                  <HelpOutline fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Rotate">
                <IconButton
                  style={{
                    background: orbiting ? "#2196f3" : undefined,
                    color: orbiting ? "white" : "inherit",
                  }}
                  onClick={() => dispatch(Actions.setOrbiting(!orbiting))}
                >
                  <ThreeSixty fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Center">
                <IconButton
                  onClick={() => fgRef.current.zoomToFit()}
                  color="inherit"
                >
                  <CenterFocusStrong fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={"Favorites"}
                style={{
                  background: favoriteOnly ? "#e91e63" : undefined,
                  color: favoriteOnly ? "white" : "inherit",
                }}
              >
                <IconButton
                  onClick={() => dispatch(setFavoriteOnly(!favoriteOnly))}
                  color="inherit"
                >
                  <FavoriteBorder fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Restart"}>
                <IconButton onClick={populateGraph} color="inherit">
                  <Refresh fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Display Mode"}>
                <IconButton
                  onClick={(e) => setDisplayModeAnchorEl(e.currentTarget)}
                  color="inherit"
                >
                  <div style={{ fontSize: 18 }}>
                    {directions.find((i) => i.code === dagMode)?.icon}
                  </div>
                </IconButton>
              </Tooltip>
              <Menu
                open={Boolean(displayModeAnchorEl)}
                onClose={() => setDisplayModeAnchorEl()}
                anchorEl={displayModeAnchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                {directions.map((i) => (
                  <MenuItem
                    onClick={() => {
                      dispatch(setDagMode(i.code));
                      setDisplayModeAnchorEl();
                      populateGraph();
                      fgRef.current.cameraPosition({ z: 900 });
                      // fgRef.current.cameraPosition(
                      //   {
                      //     x: 0,
                      //     y: 0,
                      //     z: 0,
                      //   }, // new position
                      //   null, // lookAt ({ x, y, z })
                      //   0 // ms transition duration
                      // );
                      // fgRef.current.zoomToFit();
                    }}
                  >
                    <ListItemAvatar>{i.icon}</ListItemAvatar>
                    <ListItemText primary={i.label} />
                  </MenuItem>
                ))}
              </Menu>
              <Tooltip title={"Categories"}>
                <IconButton onClick={handleCategoriesClicked} color="inherit">
                  <Icon
                    class="fa-solid fa-list-check"
                    style={{ fontSize: 18 }}
                  />
                </IconButton>
              </Tooltip>
              <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setAnchorEl()}
              >
                <div style={{ padding: 8 }}>
                  <Typography>Show/Hide categories:</Typography>
                  {categories.map((c) => (
                    <div>
                      <FormControlLabel
                        style={{ color: c.color }}
                        control={
                          <Checkbox
                            size="small"
                            style={{ color: c.color }}
                            defaultChecked={true}
                            checked={categoriesEnabled[c.id]}
                            onClick={(e) =>
                              dispatch(
                                setCategoriesEnabled({
                                  ...categoriesEnabled,
                                  [c.id]: e.target.checked,
                                })
                              )
                            }
                          />
                        }
                        label={c.title}
                      />
                    </div>
                  ))}
                </div>
              </Popover>
            </div>
          </Hidden>
          <Hidden smDown>
            <div style={{ flexGrow: 1 }} />
          </Hidden>
          <Hidden smDown>
            {selectedQuizOnGraph ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip
                  title={
                    showUnsuggestedCareers
                      ? "Hide unsuggested careers"
                      : "Show unsuggested careers"
                  }
                >
                  <IconButton
                    color="inherit"
                    onClick={() =>
                      dispatch(
                        Actions.setShowUnsuggestedCareers(
                          !showUnsuggestedCareers
                        )
                      )
                    }
                  >
                    {!showUnsuggestedCareers ? (
                      <Visibility fontSize="inherit" />
                    ) : (
                      <VisibilityOff fontSize="inherit" />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Restart">
                  <IconButton
                    color="inherit"
                    onClick={() => dispatch(Actions.setPlayCareerIndex(0))}
                  >
                    <Refresh fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Previous Career">
                  <IconButton
                    color="inherit"
                    onClick={() => dispatch(Actions.decrementPlayCareerIndex())}
                  >
                    <ArrowBackIos fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={guidedTourPlaying ? "Pause" : "Play"}>
                  <div style={{ position: "relative", width: 48, height: 48 }}>
                    {guidedTourPlaying ? (
                      <ProgressIndicator key={playCareerIndex} />
                    ) : (
                      []
                    )}
                    <IconButton
                      style={{ position: "absolute" }}
                      onClick={() =>
                        dispatch(
                          Actions.setGuidedTourPlaying(!guidedTourPlaying)
                        )
                      }
                      color="inherit"
                    >
                      {guidedTourPlaying ? (
                        <Pause fontSize="inherit" />
                      ) : (
                        <PlayArrow fontSize="inherit" />
                      )}
                    </IconButton>
                  </div>
                </Tooltip>
                <Tooltip title="Next Career">
                  <IconButton
                    color="inherit"
                    onClick={() => dispatch(Actions.incrementPlayCareerIndex())}
                  >
                    <ArrowForwardIos fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <Typography variant="caption">
                  {!isNaN(playCareerIndex)
                    ? `${playCareerIndex + 1} of ${
                        selectedQuizOnGraph.careers.length
                      } careers`
                    : `${selectedQuizOnGraph.careers.length} careers found`}
                </Typography>
                <Tooltip title="Quit Quiz inspection">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      dispatch(Actions.setSelectedCareerOnGraph());
                      dispatch(Actions.setSelectedQuizOnGraph());
                    }}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <>
                {studentQuizzes.length ? (
                  <TextField
                    style={{
                      flexGrow: 1,
                      color: "white",
                      backgroundColor: "white",
                      borderRadius: 4,
                    }}
                    select
                    variant="outlined"
                    label="Inspect your Quiz Result(s)"
                    size="small"
                    value={selectedQuizOnGraph ?? ""}
                    onChange={(e) => {
                      dispatch(Actions.setSelectedQuizOnGraph(e.target.value));
                    }}
                  >
                    {studentQuizzes?.map((s) => (
                      <MenuItem value={s}>
                        <ListItemText
                          primary={`${moment(s.date).format("lll")}`}
                          secondary={`${s.careers.length} career${
                            s.careers.length > 1 ? "s" : ""
                          } found`}
                        />
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                      dispatch(setGraphOpen(false));
                      dispatch(setQuizOpen(true));
                    }}
                  >
                    Take the Careers in Music Skills Quiz
                  </Button>
                )}
              </>
            )}
          </Hidden>
          <Hidden smDown>
            <div style={{ flexGrow: 1 }} />
          </Hidden>
          <div style={{ flex: mobile && 1 }}>
            <Button
              onClick={() => dispatch(Actions.setGraphOpen(false))}
              variant="outlined"
              color="inherit"
              fullWidth={mobile}
            >
              <ExitToApp style={{ marginRight: 4 }} /> Exit Graph
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      {selectedCareerOnGraph ? (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open
          style={{ bottom: mobile ? 55 : 64, boxShadow: "none" }}
          onClick={() =>
            dispatch(Actions.setSelectedCareer(selectedCareerOnGraph))
          }
          onClose={() => {}}
        >
          <SnackbarContent
            style={{
              background: selectedCareerOnGraph.categories[0].color,
              color: "black",
              width: !mobile && 600,
              maxWidth: mobile && "100%",
              cursor: "pointer",
            }}
            action={
              <IconButton>
                <OpenInBrowser />
              </IconButton>
            }
            message={
              <Grid container alignItems="center" justifyContent="center">
                <Grid item style={{ width: 60, marginRight: 10 }}>
                  <img src={selectedCareerOnGraph.icon} height={60} />
                </Grid>
                <Grid item style={{ width: 450 }}>
                  <Typography>{selectedCareerOnGraph.title}</Typography>
                  <Typography variant="body2">
                    {selectedCareerOnGraph.oneSentenceDescription}
                  </Typography>
                  {scoreForSelectedCareer ? (
                    <Typography
                      variant="caption"
                      display="block"
                      style={{
                        padding: "1px 4px",
                        marginTop: 4,
                        border: "solid 1px rgba(155,155,155,0.3)",
                        borderRadius: 8,
                        background: "white",
                        display: "inline-block",
                        marginLeft: 4,
                      }}
                    >{`${scoreForSelectedCareer}% Match`}</Typography>
                  ) : (
                    []
                  )}
                </Grid>
              </Grid>
            }
          />
        </Snackbar>
      ) : (
        []
      )}
      {/* <Tooltip title="More Options">
        <IconButton
          onClick={() => setMenuOpen(true)}
          style={{ position: "absolute", top: 10, left: 10, zIndex: 999 }}
        >
          <Menu />
        </IconButton>
      </Tooltip> */}
      {memoizedGraph}
      <Dialog open={helpOpen} onClose={() => setHelpOpen(false)}>
        <DialogContent>
          <Typography variant="h6">How the graph works</Typography>
          <Grid container spacing={1} style={{ marginTop: 16 }}>
            <Grid item xs={12}>
              <Paper variant="outlined" style={{ padding: 8 }}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid
                    item
                    xs={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Avatar
                      style={{ height: 60, width: 60, background: "#FF9E89" }}
                    >
                      <Icon class="fa-solid fa-arrows-up-down-left-right" />
                    </Avatar>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body2">
                      Use the left click to rotate. The Mouse-wheel/middle-click
                      to zoom. Feel free to move around the nodes on the graph.
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper variant="outlined" style={{ padding: 8 }}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid
                    item
                    xs={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Avatar
                      style={{ height: 60, width: 60, background: "#FB5842" }}
                    >
                      <Icon class="fa-solid fa-hand-pointer" />
                    </Avatar>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body2">
                      Click on a node to focus. The camera will move around the
                      node allowing you to take a closer look.
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper variant="outlined" style={{ padding: 8 }}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid
                    item
                    xs={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Avatar
                      style={{ height: 60, width: 60, background: "#FFBE71" }}
                    >
                      <OpenInBrowser />
                    </Avatar>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body2">
                      When you click on a career node, you can open the career
                      page by clicking on the snackbar that just appeared.
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper variant="outlined" style={{ padding: 8 }}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid
                    item
                    xs={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Avatar
                      style={{ height: 60, width: 60, background: "#B6ACFF" }}
                    >
                      <Icon class="fa-solid fa-clipboard-question" />
                    </Avatar>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body2">
                      If you have taken the quiz, you can inspect your quiz
                      result right on the graph and even autoplay.
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper variant="outlined" style={{ padding: 8 }}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid
                    item
                    xs={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Avatar
                      style={{ height: 60, width: 60, background: "#A5DAFF" }}
                    >
                      <Icon class="fa-solid fa-keyboard" />
                    </Avatar>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body2">
                      Finaly here are some usefull keyboard shortcuts:
                      <ul>
                        <li>
                          <i class="fa-solid fa-caret-left keyboardKey"></i>{" "}
                          Previous Career
                        </li>
                        <li>
                          <i class="fa-solid fa-caret-up keyboardKey"></i> Open
                          selected Career
                        </li>
                        <li>
                          <i class="fa-solid fa-caret-down keyboardKey"></i>{" "}
                          Close Career
                        </li>
                        <li>
                          <i class="fa-solid fa-caret-right keyboardKey"></i>{" "}
                          Next Career
                        </li>
                        <li>
                          <i class="keyboardKey">SPACE</i> Play/Pause
                        </li>
                        <li>
                          <i class="keyboardKey">ESC</i> Quit graph
                        </li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            onClick={() => setHelpOpen(false)}
          >
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function ProgressIndicator() {
  const [i, setI] = React.useState();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    setI(
      setInterval(() => {
        setProgress((p) => (p += 2));
      }, SHOW_CAREER_DURATION / 50)
    );

    return () => clearInterval(i);
  }, []);

  React.useEffect(() => {
    if (progress === 100) {
      clearInterval(i);
    }
  }, [progress]);

  return (
    <CircularProgress
      variant="determinate"
      value={progress}
      size={48}
      style={{ position: "absolute", color: "#2196f3" }}
    />
  );
}

const directions = [
  {
    code: null,
    icon: <i class="fa-solid fa-chart-network"></i>,
    label: "Forced",
  },
  {
    code: "td",
    icon: <i class="fa-solid fa-sitemap"></i>,
    label: "Top to Down",
  },
  {
    code: "bu",
    icon: (
      <i
        class="fa-solid fa-sitemap"
        style={{ transform: "rotate(180deg)" }}
      ></i>
    ),
    label: "Bottom Up",
  },
  {
    code: "rl",
    icon: (
      <i
        class="fa-solid fa-sitemap"
        style={{ transform: "rotate(-90deg)" }}
      ></i>
    ),
    label: "Right to Left",
  },
  {
    code: "lr",
    icon: (
      <i class="fa-solid fa-sitemap" style={{ transform: "rotate(90deg)" }}></i>
    ),
    label: "Left to Right",
  },
  {
    code: "radialout",
    icon: <i class="fa-solid fa-vector-circle"></i>,
    label: "Circular",
  },
];

const nodeWeight = {
  cpfm: 3,
  career: 0,
  category: 2,
  subCategory: 1,
};
