// material-ui
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// components
import * as Actions from "actions";
import App from "components/App";
import ErrorBoundary from "components/ErrorBoundary";
import Footer from "hooks/Footer";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getFullApp: (...args) => dispatch(Actions.getFullApp(...args)),
  getAuthors: (...args) => dispatch(Actions.getAuthors(...args)),
  getSkillTags: (...args) => dispatch(Actions.getSkillTags(...args)),
  getDegreeLevels: (...args) => dispatch(Actions.getDegreeLevels(...args)),
  getDegreeRequirements: (...args) =>
    dispatch(Actions.getDegreeRequirements(...args)),
  getCpfmCategories: (...args) => dispatch(Actions.getCpfmCategories(...args)),
});

class Root extends Component {
  static propTypes = {
    getFullApp: PropTypes.func,
    getAuthors: PropTypes.func,
    getCpfmCategories: PropTypes.func,
    getDegreeRequirements: PropTypes.func,
    getDegreeLevels: PropTypes.func,
    getSkillTags: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const {
      getFullApp,
      getAuthors,
      getCpfmCategories,
      getDegreeLevels,
      getDegreeRequirements,
      getSkillTags,
    } = this.props;
    getAuthors();
    getFullApp();
    getCpfmCategories();
    getDegreeRequirements();
    getDegreeLevels();
    getSkillTags();
  }

  render() {
    return (
      <ErrorBoundary>
        <Router>
          <Switch>
            <Route path="/">
              <Grid container>
                <Grid item xs={12}>
                  <App />
                </Grid>
                <Grid item xs={12}>
                  <Footer gradient />
                </Grid>
              </Grid>
            </Route>
          </Switch>
        </Router>
      </ErrorBoundary>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Root));
