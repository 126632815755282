import {
  incrementPlayCareerIndex,
  setGuidedTourPlaying,
  setOrbiting,
  setPlayCareerIndex,
  setSelectedCareer,
  setSelectedCareerOnGraph,
  setSelectedQuizOnGraph,
} from "actions";
import {
  OPEN_SELECTED_CAREER_ON_GRAPH,
  SET_GRAPH_OPEN,
  SET_GUIDED_TOUR_PLAYING,
  SET_SELECTED_CAREER_ON_GRAPH,
  SET_SELECTED_QUIZ_ON_GRAPH,
} from "constants/graph";

export function appMiddleware(store) {
  return (next) => (action) => {
    const state = store.getState();
    if (
      action.type === OPEN_SELECTED_CAREER_ON_GRAPH &&
      state.graph.selectedCareerOnGraph
    ) {
      store.dispatch(setSelectedCareer(state.graph.selectedCareerOnGraph));
      store.dispatch(setGuidedTourPlaying(false));
    }

    if (action.type === SET_SELECTED_QUIZ_ON_GRAPH) {
      store.dispatch(setPlayCareerIndex(0));
    }

    if (action.type === SET_GRAPH_OPEN && !action.graphOpen) {
      store.dispatch(setSelectedCareerOnGraph());
      store.dispatch(setSelectedQuizOnGraph());
    }

    if (action.type === SET_SELECTED_CAREER_ON_GRAPH) {
      store.dispatch(setOrbiting(false));
    }

    if (action.type === SET_GUIDED_TOUR_PLAYING) {
      if (action.guidedTourPlaying) {
        store.dispatch(incrementPlayCareerIndex());
      }
    }

    return next(action);
  };
}
