import { SET_QUIZ_OPEN, SET_SELECTED_QUIZ } from "constants/app";
import { CREATE_STUDENT_QUIZZ } from "constants/studentQuizzes";

export default (
  state = { selected: undefined, all: [], quizOpen: false },
  action
) => {
  switch (action.type) {
    case SET_QUIZ_OPEN:
      return { ...state, quizOpen: action.quizOpen };
    case SET_SELECTED_QUIZ:
      const _s = { ...state };
      _s.selected = action.value;
      return _s;
    case `${CREATE_STUDENT_QUIZZ}_SUCCESS`:
      const _state = { ...state };
      const date = new Date().getTime();
      var reducedQuiz = action.payload.reduce((a, v) => {
        a.push({ id: v.id, quizWeight: v.quizWeight });
        return a;
      }, []);

      reducedQuiz.sort((a, b) => b.quizWeight - a.quizWeight);
      const c = {
        date,
        careers: reducedQuiz,
      };
      _state.all.push(c);
      _state.selected = date;
      return _state;
    default:
      return state;
  }
};
