import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Close } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import * as actions from "actions";
import clsx from "clsx";
import AnswerBubble from "hooks/AnswerBubble";
import CareerBubble from "hooks/CareerBubble";
import FeaturedCareerGallery from "hooks/FeaturedCareerGallery";
import ShowInGraph from "hooks/ShowInGraph";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Quiz({ quizOpen, close }) {
  const [loading, setLoading] = React.useState(true);
  const [selectedQuizTags, setSelectedQuizTags] = React.useState([]);
  const classes = useStyles(styles);

  const dispatch = useDispatch();
  const cpfmCategories = useSelector((state) => state.cpfmCategories);
  const quizCategories = useSelector((state) => state.quizCategories);
  const quizTags = useSelector((state) => state.quizTags);
  const studentQuizz = useSelector((state) =>
    state.studentQuizzes.all.find(
      (c) => c.date === state.studentQuizzes.selected
    )
  );
  const mobile = !useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const fullApp = useSelector((s) => s.fullApp);
  const fullAppMap = fullApp?.reduce(function (result, c) {
    result[c.id] = c;
    return result;
  }, {});

  const colors = cpfmCategories?.reduce((a, v) => {
    a.push(v.color);
    return a;
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    if (quizOpen && quizTags.length === 0) {
      dispatch(actions.getQuizTags());
    }

    if (quizOpen && quizCategories.length === 0) {
      dispatch(actions.getQuizCategories());
    }
  }, [quizOpen]);

  useEffect(() => {
    if (quizCategories.length && quizTags.length) {
      setLoading(false);
    }
  }, [quizCategories, quizTags]);

  function submitQuiz() {
    setLoading(true);
    dispatch(
      actions.createStudentQuizz({
        tagIDs: selectedQuizTags.reduce((a, v) => {
          a.push(v.id);
          return a;
        }, []),
      })
    ).then((r) => {
      setLoading(false);
    });
  }

  function showLoadingProgress() {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.loadingProgessContainer}
      >
        <Grid item className={classes.gridItemCenter}>
          <CircularProgress />
          <Typography>Loading ...</Typography>
        </Grid>
      </Grid>
    );
  }

  const addQuizTag = (quizTag) => {
    var _selectedQuizTags = [...selectedQuizTags];
    let index = _selectedQuizTags.indexOf(quizTag);
    if (index === -1) {
      _selectedQuizTags.push(quizTag);
    } else {
      _selectedQuizTags.splice(index, 1);
    }

    setSelectedQuizTags(_selectedQuizTags);
  };

  const cat = quizCategories[activeStep];

  function showQuiz() {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            {cat.question}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Select all that apply
          </Typography>
          <br />
          <Grid container justify="center">
            {quizTags
              .filter((t) => t.categoryID === cat.id)
              .map((tag) => {
                return (
                  <Grid item key={tag.id} onClick={() => addQuizTag(tag)}>
                    <AnswerBubble
                      selectedQuizTags={selectedQuizTags}
                      tag={tag}
                      color={colors[tag.id % (colors.length - 1)]}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </div>
        <Grid
          container
          justify={mobile ? "space-between" : "center"}
          alignItems={"center"}
          style={{
            marginTop: 16,
            position: "sticky",
            bottom: -8,
            background: "rgba(255,255,255,0.8)",
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
          }}
        >
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {activeStep === quizCategories.length - 1 ? (
            <Grid item style={{ padding: 8, flex: mobile && 1 }}>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  marginTop: 20,
                  color: "black",
                  fontWeight: 600,
                  fontSize: 18,
                  borderColor: "white",
                  background: "#A5DAFF",
                }}
                fullWidth
                disabled={activeStep === 0}
                onClick={submitQuiz}
              >
                Submit & View Results
              </Button>
            </Grid>
          ) : (
            [
              <Grid item style={{ padding: 8 }}>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={activeStep === 0}
                  onClick={() => setActiveStep((a) => a - 1)}
                >
                  <i class="far fa-chevron-left p-right"></i>Previous Question
                </Button>
              </Grid>,
              <Grid item style={{ padding: 8 }}>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={activeStep === quizCategories.length - 1}
                  onClick={() => setActiveStep((a) => a + 1)}
                >
                  Next Question <i class="far fa-chevron-right p-left"></i>
                </Button>
              </Grid>,
            ]
          )}
        </Grid>
        <div>
          <Hidden smDown>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<QontoConnector />}
            >
              {quizCategories.map((label, i) => (
                <Step key={label} style={{ cursor: "pointer" }}>
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    onClick={() => setActiveStep(Number(i))}
                  >
                    Q{Number(i + 1)}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Hidden>
        </div>
      </div>
    );
  }

  function showResult() {
    const { date, careers } = studentQuizz;
    const sortedCareers = careers.sort((a, b) => b.quizWeight - a.quizWeight);

    const featured = [];
    for (let index = 0; index < 8; index++) {
      const e = sortedCareers[index];
      featured.push({ ...fullAppMap[e.id], ...e });
    }

    return (
      <div style={{ flex: 1 }}>
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", marginTop: 16, marginBottom: 16 }}
          >
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              We found {sortedCareers.length} careers for you!
            </Typography>
            <Typography variant="h6">
              This is just a starting point. Please make sure to check out some
              of the other careers.
            </Typography>
            <br />
            <ShowInGraph quiz={studentQuizz} />
            <br />
            <Button
              onClick={() => {
                dispatch(actions.setSelectedQuiz());
                setActiveStep(0);
                setSelectedQuizTags([]);
              }}
              style={{
                marginTop: 20,
                color: "black",
                fontWeight: 600,
                fontSize: 18,
                borderColor: "white",
                background: "#A5DAFF",
              }}
              variant="outlined"
            >
              Restart the Quiz
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} style={{ display: "flex" }}>
            <FeaturedCareerGallery careers={featured} />
          </Grid>
          {sortedCareers.map((c) => (
            <Grid item xs={12} md={3}>
              <div
                style={{
                  padding: "8px 0px",
                  height: "100%",
                }}
              >
                <CareerBubble
                  variant="inline"
                  career={{ ...fullAppMap[c.id], ...c }}
                  showMatch
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }

  return (
    <>
      <Dialog
        open={quizOpen}
        onClose={close}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: mobile ? classes.paperSm : classes.paper,
        }}
      >
        <div
          onClick={close}
          style={{ position: "absolute", top: 10, right: 10, zIndex: 999 }}
        >
          <IconButton>
            <Close />
          </IconButton>
        </div>
        <DialogContent className={classes.dialogContent}>
          {loading ? showLoadingProgress() : []}
          {!loading && !studentQuizz ? showQuiz() : []}
          {studentQuizz?.careers?.length ? showResult() : []}
        </DialogContent>
      </Dialog>
    </>
  );
}

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#607d8b",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#ff9800",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#607d8b",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#607d8b",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}
