import {
  setFavoriteOpen,
  setGraphOpen,
  setMyQuizesOpen,
  setQuizOpen,
  setSelectedCareer,
} from "actions";
import {
  SET_FAVORITE_OPEN,
  SET_MY_QUIZES_OPEN,
  SET_QUIZ_OPEN,
  SET_SELECTED_CAREER,
  URL_CHANGED_ACTION,
} from "constants/app";
import { SET_GRAPH_OPEN } from "constants/graph";
import { getJsonFromUrl, updateURLParams } from "helpers";

export function urlMiddleware(store) {
  return (next) => (action) => {
    const state = store.getState();

    if (action.type === URL_CHANGED_ACTION) {
      const urlParams = getJsonFromUrl(action.location);
      store.dispatch(
        setSelectedCareer(
          urlParams.careerID ? parseInt(urlParams.careerID) : null
        )
      );

      store.dispatch(setGraphOpen(urlParams.graphOpen === "true"));
      store.dispatch(setFavoriteOpen(urlParams.favoriteOpen === "true"));
      store.dispatch(setQuizOpen(urlParams.quizOpen === "true"));
      store.dispatch(setMyQuizesOpen(urlParams.myQuizesOpen === "true"));
    }

    if (action.type === SET_SELECTED_CAREER) {
      updateURLParams("careerID", action.value?.id);
    }

    if (action.type === SET_GRAPH_OPEN) {
      updateURLParams("graphOpen", action.graphOpen);
    }

    if (action.type === SET_FAVORITE_OPEN) {
      updateURLParams("favoriteOpen", action.favoriteOpen);
    }

    if (action.type === SET_MY_QUIZES_OPEN) {
      updateURLParams("myQuizesOpen", action.myQuizesOpen);
    }

    if (action.type === SET_QUIZ_OPEN) {
      updateURLParams("quizOpen", action.quizOpen);
    }

    return next(action);
  };
}
