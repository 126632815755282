import {
  RESTART_QUIZ,
  SET_CAREER_PREFERENCES,
  SET_CATEGORY_PREFERENCES,
  SET_DARK_MODE,
  SET_FAVORITE_OPEN,
  SET_MY_QUIZES_OPEN,
  SET_SEARCH_OPEN,
  SET_SELECTED_CAREER,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_QUIZ,
  URL_CHANGED_ACTION,
} from "constants/app";

export const setDarkMode = (token) => async (dispatch) =>
  dispatch({
    type: SET_DARK_MODE,
    token,
  });

export const setSearchOpen = (searchOpen) => async (dispatch) =>
  dispatch({
    type: SET_SEARCH_OPEN,
    searchOpen,
  });

export const urlChangedAction = (location) => async (dispatch) =>
  dispatch({
    type: URL_CHANGED_ACTION,
    location,
  });

export const setFavoriteOpen = (favoriteOpen) => async (dispatch) =>
  dispatch({
    type: SET_FAVORITE_OPEN,
    favoriteOpen,
  });

export const setMyQuizesOpen = (myQuizesOpen) => async (dispatch) =>
  dispatch({
    type: SET_MY_QUIZES_OPEN,
    myQuizesOpen,
  });

export const setSelectedQuiz = (value) => async (dispatch) =>
  dispatch({
    type: SET_SELECTED_QUIZ,
    value,
  });

export const setCategoryPreferences = (value) => async (dispatch) =>
  dispatch({
    type: SET_CATEGORY_PREFERENCES,
    value,
  });

export const restartQuiz = (value) => async (dispatch) =>
  dispatch({
    type: RESTART_QUIZ,
    value,
  });

export const setSelectedCareer = (value) => async (dispatch) =>
  dispatch({
    type: SET_SELECTED_CAREER,
    value,
  });

export const setSelectedCategory = (value) => async (dispatch) =>
  dispatch({
    type: SET_SELECTED_CATEGORY,
    value,
  });

export const setCareerPreferences = (value) => async (dispatch) =>
  dispatch({
    type: SET_CAREER_PREFERENCES,
    value,
  });
