import { combineReducers } from "redux"; //eslint-disable-line
import app from "./app";
import authors from "./authors";
import careerPreferences from "./careerPreferences";
import categoryPreferences from "./categoryPreferences";
import cpfmCategories from "./cpfmCategories";
import degreeLevels from "./degreeLevels";
import degreeRequirements from "./degreeRequirements";
import fullApp from "./fullApp";
import graph from "./graph";
import quizCategories from "./quizCategories";
import quizTags from "./quizTags";
import search from "./search";
import searchResults from "./searchResults";
import selectedCareer from "./selectedCareer";
import selectedCategory from "./selectedCategory";
import skillTags from "./skillTags";
import studentQuizzes from "./studentQuizzes";
import user from "./user";
export default combineReducers({
  user,
  app,
  cpfmCategories,
  categoryPreferences,
  fullApp,
  selectedCategory,
  selectedCareer,
  degreeLevels,
  degreeRequirements,
  careerPreferences,
  skillTags,
  quizCategories,
  quizTags,
  graph,
  studentQuizzes,
  authors,
  searchResults,
  search,
});
